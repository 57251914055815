%icon {
    /**
    * We'll be using the solid style of Font Awesome for this project.
    * In order to do this we:
    *
    * 1. Extend the main font awesome placeholder class %fa-icon.
    * 2. Extend .far, which is used for the 'regular' style.
    *
    */

    @extend %fa-icon;
    @extend .fas;
}

/**
* Each type of icon will:
* 1. Extend the %icon placeholder
* 2. Use a specific icon, by setting the content rule of their before
*    pseudo-element to one a variable defined in fontawesome/_variables.scss
*
* To do this, we first use a Sass list to define our own icon names:
* ...
*/

$icons: (
    'bell',
    'chair',
    'chat',
    'check',
    'chevron-right',
    'circle',
    'clock',
    'file',
    'file-pdf',
    'file-signature',
    'heart',
    'home',
    'map-pin',
    'menu',
    'people',
    'plus',
    'search',
    'search-plus',
    'search-minus',
    'share-alt',
    'text',
    'times',
    'trash',
    'dollar',
    'list',
);

/**
* ...
* Then we append those names to an '.icon--' selector, and have each result
* @extend the %icon placeholder
* ...
*/

@each $icon in $icons {
    .icon--#{$icon} {
        @extend %icon;
    }
}

/**
* ...
* Finally, we define the content of each before pseudo-element
*/

.icon {

    &--bell:before { content: fa-content($fa-var-bell) }
    &--chair:before { content: fa-content($fa-var-chair ) }
    &--chat:before { content: fa-content($fa-var-comment) }
    &--check:before { content: fa-content($fa-var-check) }
    &--chevron-right:before { content: fa-content($fa-var-chevron-right) }
    &--circle:before { content: fa-content($fa-var-circle) }
    &--clock:before { content: fa-content($fa-var-clock) }
    &--file:before { content: fa-content($fa-var-file) }
    &--file-pdf:before { content: fa-content($fa-var-file-pdf) }
    &--file-signature:before { content: fa-content($fa-var-file-signature) }
    &--heart:before { content: fa-content($fa-var-heart) }
    &--home:before { content: fa-content($fa-var-home) }
    &--people:before { content: fa-content($fa-var-user-friends) }
    &--plus:before { content: fa-content($fa-var-plus) }
    &--map-pin:before { content: fa-content($fa-var-map-marker-alt) }
    &--menu:before { content: fa-content($fa-var-bars) }
    &--search:before { content: fa-content($fa-var-search) }
    &--share-alt:before { content: fa-content($fa-var-share-alt) }
    &--search-plus:before { content: fa-content($fa-var-search-plus) }
    &--search-minus:before { content: fa-content($fa-var-search-minus) }
    &--text:before { content: fa-content($fa-var-align-left) }
    &--times:before { content: fa-content($fa-var-times) }
    &--trash:before { content: fa-content($fa-var-trash) }
    &--dollar:before { content: fa-content($fa-var-dollar-sign) }
    &--list:before { content: fa-content($fa-var-list) }

}