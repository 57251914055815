.historial-page {
  padding: 0 16px 100px 16px;


  .desktop & {
    padding: 0 24px 100px 24px;
  }

}

.event-item__extra {
  .historial-page & {
    display: flex;
    max-width: 180px;

    .desktop & {
      max-width: 100%;
    }
  }
}

.event-item__credits--text {
  .historial-page & {
    width: 80px;
    flex-shrink: 0;
  }
}

.event-item__user--text {
  .historial-page & {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.control--ver-mas {
  @extend %button--primary;

  .historial-page & {
    color: $color--attention;
    background: none;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    // border: 2px solid;
    // border-radius: 4px;
  }
}

.control--ver-mas__icon {
  transform: rotate(-90deg);
  margin-inline-start: 10px;
}

.control--ver-menos__icon {
  transform: rotate(90deg);
  margin-inline-start: 10px;
}