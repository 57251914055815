$puesto--height: 91px;
$puesto--width: 94px;

.mesa {
    --puesto--height: #{$puesto--height} ; // El valor estaba diseñado para mesa de 6
    --puesto--width: #{$puesto--height}; // El valor estaba diseñado para mesa de 6
    --puestos-por-fila: calc(var(--puestos) / 2);

    .mobile & {
        --puesto--height: calc(#{$puesto--height} * 6 / var(--puestos)); // El valor estaba diseñado para mesa de 6
        --puesto--width: calc(#{$puesto--height} * 6 / var(--puestos)); // El valor estaba diseñado para mesa de 6
    }

    /* Positioning */
    position: relative;

    /* Box Model */
    // height: calc(#{$puesto--height} * 2);
    height: calc(var(--puesto--height) * 2);
    //margin:  16px 0 0 ;
    //padding: 16px;
    max-width: 100%;
    width: auto;

    // background-color: #ffc7b2;
    border-radius: 8px;
    //overflow: hidden;

    width: calc(var(--puesto--width) * var(--puestos-por-fila));

    &--2 {
        --puestos: 2;
        // max-width: calc(#{$puesto--width} * 1);
    }
    &--4 {
        --puestos: 4;
        // max-width: calc(#{$puesto--width} * 2);
    }
    &--6 {
        --puestos: 6;
        // max-width: calc(#{$puesto--width} * 3);
    }
    &--8 {
        --puestos: 8;
        // max-width: calc(#{$puesto--width} * 4);
    }
    &--10 {
        --puestos: 10;
        // max-width: calc(#{$puesto--width} * 5);
/*
        // Parches para mesa de 10 puestos -no entraban bien con el diseño existente-
        .mobile & {
            --puesto-width: calc(var(--puesto--width) * 4 / var(var(--puestos-por-fila)));
            max-width: calc(var(--puesto-width) * var(--puestos-por-fila));
            height: calc(#{$puesto--height} * 2);

            .escritorio {
                padding: 0;
            }
        }
        .desktop & {
            width: calc(#{$puesto--width} * 5);
        }
        // scale: 0.6;
*/
    }

}

.mesa__puestos {
    /* Box Model */
    height: 100%;
    margin: 0 ;
    padding: 0;
    width: 100%;

    /* Flex box */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    /* Other */
    list-style-type: none;

}

.mesa__puesto {
/*
    height: $puesto--height;
    width: 100%;
    max-width: calc(100% / var(--puestos-por-fila));
     */
    height: var(--puesto--height);
    width: var(--puesto--width);
/*
    .mesa--2 & {
        max-width: calc(100% / 1);
    }
    .mesa--4 & {
        max-width: calc(100% / 2);
    }
    .mesa--6 & {
        max-width: calc(100% / 3);
    }
    .mesa--8 & {
        max-width: calc(100% / 4);
    }
    .mesa--10 & {
        max-width: calc(100% / 5);
        .mobile & {
            $puesto--height: 76px;
            height: $puesto--height;

            .escritorio {
                height: $puesto--height;
            }
        }
    }
*/

    .escritorio {
        width: 100%;
    }

    .desktop & {
        // max-width: $puesto--width;
    }
}
