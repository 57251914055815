.firmar-page {
  $page-padding-inline: 16px;
  $page-padding-bottom: 100px;

  padding-inline: $page-padding-inline;
  padding-block-end: $page-padding-bottom;

  padding: 0 16px 164px 16px;
  background-color: $background-color--secondary;

  .mobile & {
    min-height: calc(100vh - #{$nav-height--mobile} - #{$status-height--mobile});
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .fixed-CTA__control {
    width: 300px;
  }

  .mobile & {
    .fixed-CTA__control {
      width: 100%;
    }
  }

  .desktop & {
    padding: 0 24px 100px 24px;
    min-height: calc(100vh - #{$status-height--desktop});

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
      justify-content: center;

    }

  }

  .signature {
    display: block;
    margin: 100px auto;
    border: 2px solid #ffac8f;
    max-width: 500px;
    width: 100%;
    height: 200px;
    margin: 0;
  }

  & .attention {
    color: $color--attention;
  }

}
