.contrato-card {
  display: flex;
  flex-direction: column;

  /* Icons */
  *[class^="icon"] {
    /* Font Awesome */
    font-size: 16px;
    width: 16px;
    height: 16px;

    .desktop & {
        /* Font Awesome */
        font-size: 24px;

        width: 24px;
        height: 24px;
    }
  }

  & .attention {
    color: $color--attention;
  }

}

.contrato__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-around;
  margin-block-end: 1em;

}

.contrato__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
  word-spacing: normal;
  margin: 10px;

  &.inline {
    flex-direction: row;

    span {
      margin-inline-start: 10px;
    }
  }
}