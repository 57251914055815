.transacciones-page {
  padding: 0 16px 100px 16px;
  height: 100%;


  .desktop & {
    padding: 0 24px 100px 24px;
  }

}

.filtros {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  margin-block-end: 20px;

  .desktop & {
    flex-direction: row;
    justify-content: space-evenly;
    margin-block: 40px;
  }
}

.control--aplicar {
  .transacciones-page & {
    @extend %button--primary;
    border-radius: 4px;
  }
}

.input-label-combo {
  .transacciones-page & {
    margin: 0;
    margin-block-end: 20px;
    .desktop & {
      margin: 0;
    }
  }
}

.transacciones-list {
  max-height: calc(100vh - 32px - 14px - 20px - 80px - 70px - 100px - 68px);
  .desktop & {
    min-width: fit-content;
  }
}

.transacciones-columns {
  display: none;
  margin: auto;
  margin-bottom: 14px;

  font-size: 20px;
  font-weight: 600;

  border-bottom: 1px solid $background-color--tertiary;

  .desktop & {
    display: flex;
    flex-direction: row;
  }

}

.transacciones-item {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 14px;
  border: 1px solid $color--attention;
  border-radius: 4px;
  padding: 20px;

  .desktop & {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 14px;
    border: none;
    border-bottom: 1px solid #3333;
  }
}

.item--nombre {
  .transacciones-item & {
    font-weight: bold;
    .desktop & {
      display: none;
    }
  }
}

.transacciones-items {
  list-style: none;
  padding: 0;
  margin: 0;

  overflow-y: scroll;
  max-height: calc(100vh - 32px - 14px - 20px - 20px - 70px - 100px - 68px);
}

.transacciones-item__date {
  display: flex;
  flex-direction: column;

  .desktop & {
    flex-direction: row;
    width: 100px;

  }
}

.transacciones-item__details {
  display: flex;
  flex-direction: column;

  .desktop & {
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

}

.transacciones-item__type {
  .desktop & {
    width: 300px;
  }
}

.transacciones-item__extra {
  .transacciones-page & {
    display: flex;
    max-width: 180px;

    .desktop & {
      max-width: 100%;
    }
  }
}

.transacciones-item__credits,
.transacciones-item__saldo {
  .transacciones-page & {
    text-align: right;
    .desktop & {
      width: 120px;
    }
  }
}

.transacciones-item__espacio {
  .transacciones-page & {
    .desktop & {
      width: 200px;
    }
  }
}

.transacciones-item__solicitante,
.transacciones-item__aprobador {
  .transacciones-page & {
    .desktop & {
      width: 200px;
    }
  }
}

.transacciones-item__user--text {
  .transacciones-page & {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.control--ver-mas {
  @extend %button--primary;

  .transacciones-page & {
    color: $color--attention;
    background: none;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    // border: 2px solid;
    // border-radius: 4px;
  }
}

.control--ver-mas__icon {
  transform: rotate(-90deg);
  margin-inline-start: 10px;
}

.balance__deudor {
  color: $color--warning;
}

.balance__acreedor {
  color: $color--success;
}

.balance {
  .transacciones-page & {
    // padding: 14px;
    border: solid 1px;
    border-radius: 4px;
    font-size: 1.5em;
    min-width: 210px;
    // text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  label {
    --input-vertical-padding: -11px;
    /* background-color: white; */
    background-image: linear-gradient(to top, white, white calc((20px / 2) + 1px), rgba(0, 0, 0, 0) calc((20px / 2) + 1px), rgba(0, 0, 0, 0) 20px);
    /* --line-height: 17px; */
    --font-size: 1em;
  }
}