.escritorio {
    /* Positioning */
    position: relative;

    /* Box Model */
    // height: 91px;
    height: var(--puesto--height);
    //margin:  16px 0 0 ;
    --escritorio-padding: 16px;
    padding: var(--escritorio-padding);
    // width: 94px;
    width: var(--puesto--width);

    .mobile & {
        padding: calc(var(--escritorio-padding) * 6 / var(--puestos));
    }

    /* Flex box */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // background-color: white;
    // box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: inset 2px 2px rgba(0, 0, 0, 1), inset -2px -2px rgba(0, 0, 0, 1);
    // border: solid 2px;

    .escritorio__posicion {
        position: absolute;
        --posicion--left: 6px; // Diseñado para 6 puestos
        --posicion--top: 1px; // Diseñado para 6 puestos
        --font-size: 20px; // Diseñado para 6 puestos
        left: var(--posicion--left) ;
        // top: 8px;
        top: var(--posicion--top);
        margin: auto;
        font-size: var(--font-size);

        .mobile & {
            left: calc(var(--posicion--left) * 6 / var(--puestos)); // Diseñado para 6 puestos
            top: calc(var(--posicion--top) * 6 / var(--puestos)); // Diseñado para 6 puestos
            font-size: calc(var(--font-size) * 6 / var(--puestos)); // Diseñado para 6 puestos
        }
    }

    .escritorio__ocupantes {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &--habilitado {
        // background: center/20% rgba(246, 105, 29, 0.3) url('/img/O.svg') no-repeat;
        background-image: url(/img/O.svg);
        background-position: center;
        background-repeat: no-repeat;
    }

    &--inhabilitado {
        //background-color: burlywood;
        background: center/80% $background-color--disabled url('/img/X.svg') no-repeat;

        .silla {
            visibility: hidden;
        }
    }

    &--ocupado {
        background: $background-color--tertiary;
        .escritorio__ocupantes {
            visibility: visible;
        }
    }

    &--reservado {
        background: $background-color--attention;
        .escritorio__ocupantes {
            visibility: visible;
        }
    }

    .is-folded .selector-chevron {
        rotate: -90deg;
    }

    .is-unfolded .selector-chevron {
        rotate: 90deg;
    }

}

.escritorio__control {
    @extend %button--no-background;
    @extend %button--icon-only;

    /* Box Model */
    height: 100%;
    width: 100%;

}
.escritorio__ocupante-nombre__multi {
    // .escritorio & {
        width: calc(100% - 80px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    // }
}
.escritorio__ocupante-avatar-image__multi {
    margin-left: 4px;
}

.escritorio__ocupantes-avatar {
    .escritorio & {
        min-width: auto;
    }
}

.escritorio__ocupantes-avatar-image {
    .escritorio & {
        /* Box Model */
        // width: 48px;
        // height: 48px;
        width: 56px;
        height: 56px;

        .mobile & {
            width: calc(var(--puesto--width) * 6 / var(--puestos));
            height: calc(var(--puesto--height) * 6 / var(--puestos));
        }

        /* Flexbox */
        display: flex;
        justify-content: center;
        align-items: center;

        /* Other */
        background-color: #6662A4;
        border: none;
        border-radius: 50%;

    }
    /* Box Model */
    width: 48px;
    height: 48px;

    /* Flexbox */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Other */
    background-color: #6662A4;
    border: none;
    border-radius: 50%;

}
.escritorio__ocupantes-avatar-image__text {
    position: relative;
    top: -1px;
    left: 1px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: $color--inverted;

}
.escritorio__ocupantes-nombre {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    bottom: 12px;
    width: 100%;

    span {
        display: inline-block;
        height: 15px;
        max-width: 70%;
        width: fit-content;
        margin: auto;
        padding: 0 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 10px;
        font-weight: 500;
        line-height: 12px;

        background-color: $background-color--inverted;
        color: $color--inverted;
        border-radius: 30px;

        visibility: hidden;

        &.nombres--1 {
            visibility: visible;
        }
    }
}

.info-de-escritorio__image {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;
    background-size: contain;
    .desktop & {
        height: 300px;
        width: 500px;
    }
}

.silla {
    position: absolute;
    width: 100%;
    left: 0;

    &--TOP {
        top: -29px;
    }

    &--BOTTOM {
        bottom: -29px;
        transform: rotate(180deg);
    }

}

.silla__image {
    max-width: 100%;
    height: 30px;
    display: block;
    margin: auto;
}