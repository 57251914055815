.reservas-de-salas { }

.reservas-de-salas__instrucciones {
    /* Typography */
    color: #888;
    font-size:   12px;
    font-weight: 400;
    line-height: 20px;

    .desktop & {
        /* Typography */
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Adieu, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 16px;

    }
}

.wrapper--reservas-de-salas__layout {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.wrapper--reservas-de-sala__layout {
    /* Flexbox */
    display: flex;
    flex-direction: row;

    /* Box Model */
    width:     100%;
    max-width: calc(100% - 60px);
    overflow-x: auto;
}

.horarios-disponibles,
.reservas-de-sala {
    /* Box Model */
    margin:  0;
    padding: 0;

    /* Flexbox */
    display: flex;
    flex-direction:  column;
    justify-content: flex-start;
    align-items:     center;

    flex: 0 0 60px;

    /* Other */
    list-style-type: none;
}

.horarios-disponibles {
    /* Variables */
    $top-spacing: 35px; // This must be the same as $room-name--height

    /* Box Model */
    padding-top: $top-spacing;
}

.horarios-disponibles__slot-horario {
    /* Variables */
    $time-slot--height: 50px;

    /* Box Model */
    height: $time-slot--height;

    /* Typography */
    color: #0008;
    font-size:      12px;
    letter-spacing: 0.25px;
    line-height:    16px;

    /* Hack para mostrar divisiones horizontales por time-slot */
    width: 60px;

    span {
        /* Positioning */
        position: relative;
        top:      8px; /* Centering text based on a 34px high avatar, 8px + half the line height */

        /* Hack para mostrar divisiones horizontales por time-slot */
        display: inline-block;
        width: 100vw;
        height: calc((#{$time-slot--height} / 2) + 8px);
        box-shadow: 0 1px 0 0 #3331;
    }

    /* Hack para mostrar divisiones horizontales por time-slot */
    &:last-child {
        span {
            box-shadow: none;
        }
    }
}

.reservas-de-sala {
    /* Positioning */
    position: relative; /* Creating a positioning context for .reserva-de-sala */

    /* Other */
    box-shadow: 1px 0 0 0 #0002;
}

.reservas-de-sala__nombre-de-sala {
    /* Variables */
    $room-name--height: 35px;

    /* Box Model */
    height: $room-name--height;

    /* Typography */
    color: #C4C4C4;
    font-size:   12px;
    line-height: 18px;

    text-align: center;

    span {
       /* Positioning */
       position: relative;
       top:      2px; /* Creating extra spacing between the edge of the list */
    }
}

.reserva-de-sala {
    /* Variables */
    $room-booking--height: 50px;
    $avatar-dimensions--height: 34px;
    $room-booking--bottom-spacing: $room-booking--height - $avatar-dimensions--height;

    /* Positioning */
    position: absolute; /* Create a positioning context for pseudo-element */
    top:      35px; /* Positioned at .reservas-de-sala__nombre-de-sala's height */

    /* Box Model */
    min-height: $room-booking--height;

    &::after {
        content: '';

        /* Positioning */
        position: absolute;
        top:      0;
        bottom:   0;
        z-index:  1;

        /* Box Model */
        display: block;
        width:   34px;
        height:  calc(100% - #{$room-booking--bottom-spacing});

        /* Other */
        background-color: #F8C0A2;
        border-radius: 17px;
    }
}

$duraciones: (
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
    '41', '42', '43', '44', '45', '46', '47', '48',
);

@each $duracion in $duraciones {
    .duracion-en-slots--#{$duracion} {
        /* Variables */
        $room-booking--height: 50px;

        /* Box Model */
        height: calc(#{$room-booking--height} * #{$duracion});
    }
}

$slots-iniciales: (
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
    '41', '42', '43', '44', '45', '46', '47', '48',
);

@each $slot in $slots-iniciales {
    .inicia-en-slot--#{$slot} {
        /* Variables */
        $room-booking--height: 50px;

        /* Positioning */
        top: calc( ( #{$room-booking--height} * ( #{$slot} - 1 ) ) + 35px);
    }
}

.reserva-de-sala__usuario { }
.reserva-de-sala__avatar-usuario {
    /* Variables */
    $avatar-dimensions--height: 34px;
    $avatar-dimensions--width:  34px;

    /* Positioning */
    position: relative;
    z-index:  10; /* Making sure the image renders above the .reserva-de-sala pseudo-element */

    /* Box Model */
    height: $avatar-dimensions--height;
    width:  $avatar-dimensions--width;

    /* Flexbox */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Other */
    background-color: #6662A4;
    border: none;
    border-radius: 50%;
}

.reserva-de-sala__avatar-usuario__text {
    position: relative;
    top: -1px;
    left: 1px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: $color--inverted;
}
